.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper {
    width: 100%;
    padding: 20px;
    padding-top: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logoGKJ {
      display: flex;
      align-items: center;

      .bako {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding-left: 15px;
        padding-right: 15px;
        -webkit-box-shadow: 5px 8px 10px 1x rgba(0, 0, 0, 0.47);
        box-shadow: 5px 8px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 5px;
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 40px;
          height: 40px;
          border: 0.5px solid lightgray;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}