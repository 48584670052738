.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    // status: 
    // untuk transaksi: Verifikasi, Belum Verifikasi
    // untuk user: Aktif, Tunda, Keluar, Lainnya
    // untuk maintenane: Setup, Add, Update
    // untuk saldoawal: Setup, Update, PrsAkhThn

    &.Approved {
      color: blue;
      background-color: rgba(0, 0, 255, 0.151);
    }
    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
    &.Aktif {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Tunda {
      color: rgb(128, 90, 0);
      background-color: rgba(128, 90, 0, 0.151);
    }
    &.Keluar {
      color: red;
      background-color: rgba(128, 0, 0, 0.151);
    }
    &.Lainnya {
      color: rgb(128, 119, 0);
      background-color: rgba(128, 119, 0, 0.151);
    }
    &.Setup {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Add {
      color: rgb(0, 128, 98);
      background-color: rgba(0, 128, 98, 0.151);
    }
    &.Update {
      color: rgb(0, 45, 128);
      background-color: rgba(0, 45, 128, 0.151);
    }
    &.PrsAkhThn {
      color: rgb(105, 128, 0);
      background-color: rgba(105, 128, 0, 0.151);
    }
  }
}
