* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    color: #000
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 100%;
    min-height: 100vh;
    // overflow: hidden;
}

.bg-ag1 {
    background-image: url("../../images/ag1.jpg");
}

.bg-ag2 {
    background-image: url("../../images/ag2.jpg");
}

.bg-ag3 {
    background-image: url("../../images/ag3.jpg");
}

.bg-ag4 {
    background-image: url("../../images/ag4.jpg");
}

.bg-ag5 {
    background-image: url("../../images/ag5.jpg");
}

.bg-ag6 {
    background-image: url("../../images/ag6.jpg");
}

.bg-ag7 {
    background-image: url("../../images/ag7.jpg");
}

.bg-ag8 {
    background-image: url("../../images/ag8.jpg");
}

.bg-ag9 {
    background-image: url("../../images/ag9.jpg");
}

.bg-ag10 {
    background-image: url("../../images/ag10.jpg");
}

.bg-ag11 {
    background-image: url("../../images/ag11.jpg");
}

.bg-ag12 {
    background-image: url("../../images/ag12.jpg");
}

.bg-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-control:focus {
    border-width: medium !important;
    border-color: #ffc400ec !important;
    box-shadow: 0 0 25px rgb(0, 255, 51) !important;
}