table {
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    font-size: 12px;
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    /* background-color: #4CAF50; */
    background-color: #66b468;
    color: white;
}

.pageControl {
    display: flex;
    font-size: 14px;
}